@import '../../../styles/preset';

.downloadButton {
  border: none;
  outline: none;
  all: unset;
  cursor: pointer;
  display: inline-block;
  color: #49a6c8;
   text-decoration: none;
}
.subtitle {
  align-self  : center;
  font-size: medium;
  font-weight: 500;
  color: $f-gray;
}

.downloadIcon {
  cursor: pointer;
  color: $f-blue;
  margin-right: 5px;
}

.document-preview-subheader {
  color: $f-dark-blue;
  font-size: medium;
  font-weight: bold;
}

.img {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  cursor: pointer;
}

.document-preview {
  width: 100%;
}

.date {
  color: $f-gray;
}
