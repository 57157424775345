@import 'colors';
@import 'sizes';

// Prefix "f" means "flow", the project name.
// We have to prefix the variables in order to not override Bootstrap's variables.

// Text.
$f-primary-font-size: 14px;
$f-primary-line-height: 16px;
$f-primary-font-family: 'Roboto Mono', monospace;

$f-secondary-font-size: 12px;
$f-secondary-line-height: 14px;

// Colors.
$f-primary-text-color: #3C3E41;


// Dropdown menu.

$f-dropdown-header-font-size: 18px;
$f-dropdown-header-font-weight: 500;
$f-dropdown-header-line-height: 21px;

// z - indexes

$f-header-z-index: 21;
$f-dropdownmenu-z-index: 1000;
$f-table-z-index: 9;

// constant dimensional values
 $f-th-actions: 54px;