@import '../../styles/preset';
@import  '../../styles/_table_grey' ;
@import '../../components/tables/table.scss';

 .myTable {
 width: 100%;
 }

.thPk {
  width: 10%;
}

.thName {
  width: 19%;
}

.thCreate {
  width:  15%;
}

.thSentToArchive {
  width: 13%
}

.thReceivedToArchive {
  width: 13%
}

.title {
  color: $f-dark-blue;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 28px;
  padding: 0 0 5px 40px;
  margin-left: -9px;
}

.tableHeader {
  width: 100%;
  background-color: $f-light-gray;
  color: $f-dark-blue;
}




.svgIcon {
  color:  $f-dark-blue;
}

.wrapperSubHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableIconsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25px;
}

.buttonViewFiles {
  margin-left: auto;
}

.iconContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;

}

.filename {
  display: inline-block;
  max-width: 150px;
  word-break: break-all;  
}

