@import '../../styles/_variables';
@import '../../styles/_colors';

// Общие стили для активных строк
%active-row-base {
  position: relative;
  background-image: linear-gradient(
    45deg,
    rgba($f-blue, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba($f-blue, 0.1) 50%,
    rgba($f-blue, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 6px 6px;

  td {
    position: relative;
    background: transparent !important;
    box-shadow: inset 0 0 8px rgba($f-blue, 0.1),
      inset 0 0 2px rgba($f-blue, 0.3);

    &:first-child {
      border-left: 3px solid rgba($f-blue, 0.5);
    }
  }
}

// Стили для активной строки
tr.trActive {
  @extend %active-row-base;
}

// Стили для цветной активной строки
tr.trActiveColor {
  @extend %active-row-base;

  td:first-child {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 90%;
      width: 3px;
      background: $f-blue;
      border-radius: 2px;
      opacity: 0.8;
    }
  }
}

// Стили для архивных строк
tr.trArchive {
  background: $f-light-gray !important;

  td {
    color: $f-dark-gray !important;
    font-style: italic;
    position: relative;
  }
}

// Утилитарные классы
.buttonCSVLink {
  text-decoration: none;
  color: white;

  &:hover,
  &:active {
    text-decoration: none;
    color: white;
  }
}

.thIcon {
  height: 100%;
  padding: 5px 7px 5px 5px;
}
