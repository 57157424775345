@import '../../../styles/generalStyles/dropzoneField.scss';

.container-dropzone {
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 20px;
  border: 3px  #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 20px;

  .dropzone {
    width: 100%;
    height: 100%;
    span {
      font-size: 2rem;
      color: #777;
    }
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    margin-bottom: 20px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
